(function () {
	'use strict';
	angular
		.module('calendar')
		.directive('uiCalendar', [
			'$window',
			'$rootScope',
			'seedcodeCalendar',
			'calendarIO',
			'utilities',
			'environment',
			uiCalendar,
		])
		.directive('addEvent', [
			'$rootScope',
			'calendarIO',
			'calendarEvents',
			'seedcodeCalendar',
			addEvent,
		]);

	function uiCalendar(
		$window,
		$rootScope,
		seedcodeCalendar,
		calendarIO,
		utilities,
		environment
	) {
		let calendarInitialized = false;
		return {
			restrict: 'A',
			scope: {
				eventSources: '=',
			},
			link: function (scope, element, attrs) {
				var sourcesChanged = false;
				var viewRenderListener;

				seedcodeCalendar.init('element', element);

				function getOptions() {
					var calendarSettings = attrs.uiCalendar
						? scope.$parent.$eval(attrs.uiCalendar)
						: {};

					calendarSettings.eventSources = scope.eventSources;
					return calendarSettings;
				}

				function destroy() {
					if (scope.calendar) {
						scope.calendar.fullCalendar('destroy');
					}
					scope.calendar = element;
				}

				function init() {
					var options = getOptions();
					//Create fullCalendar instance
					options.height =
						$window.innerHeight - $('#header').outerHeight();
					destroy();

					calendarInitialized = true;
					options.getInitialized = getInitialized;
					scope.calendar.fullCalendar(options);
					if (!environment.isMobileDevice && !viewRenderListener) {
						initDragSelect();
					}
					seedcodeCalendar.init('calendarViewRendered', true);
				}

				//Watch for init command (usually called when changing a setting)
				// Initially this is listening for a broadcast from init-services
				const initCalendar = seedcodeCalendar.get('initCalendar');
				if (initCalendar) {
					init();
				}
				scope.$on('initCalendar', function () {
					init();
				});

				//Remove listener when calendar is destroyed
				scope.$on('$destroy', function () {
					// Don't run destroy method here as we may need the calendar to clean up things
					// It will get removed from the dom anyway so no need to call the method explicitely
					calendarInitialized = false;
					removeEventListener('keydown', detectKeyboardShortcuts);
				});

				//Keyboard Listener for Undo and Redo events
				addEventListener('keydown', detectKeyboardShortcuts);

				function getInitialized() {
					return calendarInitialized;
				}

				//Handle keyboard events
				function detectKeyboardShortcuts(evt) {
					// Check for Shift-Z key
					if (evt.code == 'KeyZ' && evt.shiftKey) {
						let config = seedcodeCalendar.get('config');

						// Skip input elements and input textareas if in focus or a modal is open
						// or if the handler has been disabled programmatically
						if (
							config.preventUndoKeyboardHandler ||
							document.activeElement.tagName === 'INPUT' ||
							document.activeElement.tagName === 'SELECT' ||
							document.activeElement.tagName === 'TEXTAREA' ||
							document.querySelector('.dbk_popover') !== null ||
							document.querySelector('.modal-dialog') !== null
						) {
							return;
						}

						if (!config.preventUndoKeyboardShortcut) {
							// Prevent other keyboard event propagation
							evt.stopImmediatePropagation();

							// Run revert function, with parameter informing action was
							// triggered through keyboard shortcut

							calendarIO.undoHandler.runRevertFunction(true);
						}
					}
				}

				function initDragSelect() {
					var limits;
					var moved;
					var downX;
					var downY;
					var dragX;
					var dragY;
					var selectDiv;
					var scrollReference = {};
					var dragSelectContainers = [];

					//Reset Listeners whenever the view is rendered
					//The objects that the click events are on are destroyed and recreated
					viewRenderListener = scope.$on(
						'viewRendered',
						attachMouseDownListeners
					);

					attachMouseDownListeners();

					function attachMouseDownListeners() {
						//Remove old listeners
						dragSelectContainers.forEach(function (container) {
							container.removeEventListener(
								'mousedown',
								mouseDown
							);
						});
						dragSelectContainers = [];

						//Attach mouse down event listeners to calendar scroll and all day elements
						addMouseDownListener(
							document.querySelector('.calendar-scroll')
						);
						addMouseDownListener(
							document.querySelector('.fc-agenda-allday')
						);

						function addMouseDownListener(element) {
							if (element) {
								dragSelectContainers.push(element);
								element.addEventListener(
									'mousedown',
									mouseDown
								);
							}
						}
					}

					//Mouse down event listener for drag select
					function mouseDown(e, selectContainer) {
						var selectContainer = e.currentTarget;
						limits = selectContainer.getBoundingClientRect();
						if (
							//Event is left click
							e.button === 0 &&
							//mouseDown is not on the scrollbar
							e.clientX - limits.left <
								selectContainer.clientWidth &&
							//Target is not an event, popover, or breakout title
							!checkForParentClass(e.target, selectContainer, [
								'fc-event',
								'nub-content',
								'horizon-breakout-title',
								'ng-popover',
							]) &&
							//Multiselect update is not in progress
							!calendarIO.getMultiselectUpdateInProgress()
						) {
							downX = e.clientX - limits.left;
							downY =
								e.clientY +
								selectContainer.scrollTop -
								limits.top;
							moved = false;

							//Add event listeners for tracking drag
							addEventListener('mousemove', mouseMoveListener);

							//Add event listener for scrolling the calendar container
							selectContainer.addEventListener(
								'scroll',
								mouseMoveListener
							);

							//Add event listener to window to capture all mouse up events
							//even if they are outside the browser
							addEventListener('mouseup', mouseUpListener);
						}

						function mouseMoveListener(e) {
							mouseMove(e, selectContainer);
						}

						function mouseUpListener(e) {
							var select;
							var clientEvents;
							var toggledEvents = [];
							var calendarView = seedcodeCalendar.get('view');
							var clientEvents = {};
							var allDayContent =
								document.querySelector('.fc-agenda-allday');

							//Remove event listeners from calendar content so that it doesn't interfere with normal drag/click behavior
							removeEventListener('mouseup', mouseUpListener);
							removeEventListener('mousemove', mouseMoveListener);
							selectContainer.removeEventListener(
								'scroll',
								mouseMoveListener
							);

							//Drag select happened. Check for selected events
							if (
								moved &&
								selectDiv &&
								//Multiselect update is not in progress
								!calendarIO.getMultiselectUpdateInProgress()
							) {
								//Get an object of client events with the element id as the key
								seedcodeCalendar
									.get('element')
									.fullCalendar('clientEvents')
									.forEach(function (clientEvent) {
										clientEvents[clientEvent._id] =
											clientEvent;
									});

								//Clear existing multiselect
								calendarIO.toggleMultiSelect(
									null,
									null,
									null,
									calendarView,
									true
								);

								//Get bounding rect of select div
								select = selectDiv.getBoundingClientRect();

								//Check each event to see if it is in the select div
								document
									.querySelectorAll(
										'.fc-event, .nub-container'
									)
									.forEach(function (eventElement) {
										var eventRect;

										var clientEvent =
											clientEvents[
												eventElement.getAttribute(
													'data-id'
												)
											];

										var eventInParent =
											selectContainer.classList.contains(
												'calendar-scroll'
											)
												? !allDayContent ||
													!clientEvent.allDay
												: clientEvent.allDay;
										if (
											clientEvent &&
											//Only include events that are in the select container
											eventInParent &&
											!toggledEvents.includes(clientEvent)
										) {
											eventRect = (
												eventElement.classList.contains(
													'nub-container'
												)
													? eventElement.firstChild
													: eventElement
											).getBoundingClientRect();

											if (
												eventRect.right >=
													select.left &&
												eventRect.left <=
													select.right &&
												eventRect.top <=
													select.bottom &&
												eventRect.bottom >= select.top
											) {
												//Add to multiSelect
												toggledEvents.push(clientEvent);

												calendarIO.toggleMultiSelect(
													clientEvent,
													true,
													eventElement,
													calendarView
												);
											}
										}
									});
							}

							//Remove Select DIV from DOM
							if (selectDiv) {
								selectDiv.remove();
								selectDiv = null;
							}
						}
						//Checks to see if an element is within another parent element
						function checkForParentClass(
							element,
							topLevelElement,
							classNames
						) {
							var result = false;
							var parent = element;
							while (
								parent &&
								parent.classList &&
								parent !== topLevelElement &&
								!classNames.some(function (item) {
									result = parent.classList.contains(item);
									return result;
								})
							) {
								parent = parent.parentNode;
							}
							return (
								result || (parent && parent !== topLevelElement)
							);
						}
					}

					//Mouse move event listener for drag select
					function mouseMove(e, selectContainer) {
						var scrollDifference =
							selectContainer.scrollTop -
							scrollReference.scrollTop;
						dragX =
							e.type === 'scroll'
								? dragX
								: e.clientX - limits.left;
						dragY =
							e.type === 'scroll' && selectDiv
								? scrollReference.y + scrollDifference
								: Math.max(
										0,
										e.clientY +
											selectContainer.scrollTop -
											limits.top
									);

						//Mouse must move a minimum of 3 pixels before drag is started
						if (
							downX &&
							downY &&
							(moved ||
								Math.abs(dragX - downX) > 3 ||
								Math.abs(dragY - downY) > 3)
						) {
							//Create and show the selected area
							if (!selectDiv) {
								selectDiv = document.createElement('div');
								selectDiv.id = 'dragSelect';
								selectDiv.className = 'drag-select';
								selectContainer.appendChild(selectDiv);
							}
							moved = true;

							//Autoscroll if mouse is past edge of screen
							if (
								dragY < selectContainer.scrollTop &&
								selectContainer.scrollTop > 0
							) {
								selectContainer.scrollTop =
									selectContainer.scrollTop - 4;
							} else if (
								dragY >
									selectContainer.offsetHeight +
										selectContainer.scrollTop &&
								Math.ceil(selectContainer.scrollTop) <
									selectContainer.scrollHeight -
										selectContainer.offsetHeight
							) {
								selectContainer.scrollTop =
									selectContainer.scrollTop + 4;
							}

							//Account for scroll in drag selection
							if (
								e.type === 'scroll' &&
								Math.ceil(dragY) < selectContainer.scrollHeight
							) {
								if (dragY > downY) {
									selectDiv.style.height =
										dragY - downY + 'px';
								} else {
									selectDiv.style.height =
										downY - dragY + 'px';
									selectDiv.style.top = dragY + 'px';
								}
							} else {
								//Save our reference to the scrollTop relative position for scroll events
								scrollReference = {
									y: dragY,
									scrollTop: selectContainer.scrollTop,
								};
								selectDiv.style.left =
									(dragX < downX ? dragX + 1 : downX) + 'px';
								selectDiv.style.top =
									(dragY < downY ? dragY : downY) + 'px';
								selectDiv.style.width =
									(dragX < downX
										? downX - dragX
										: dragX - downX - 1) + 'px';
								if (
									Math.ceil(dragY) <
									selectContainer.scrollHeight
								) {
									selectDiv.style.height =
										(dragY < downY
											? downY - dragY
											: dragY - downY) + 'px';
								} else {
									selectDiv.style.height =
										selectContainer.scrollHeight -
										3 -
										Math.ceil(downY) +
										'px';
								}
							}
						}
					}
				}
			},
		};
	}

	function addEvent(
		$rootScope,
		calendarIO,
		calendarEvents,
		seedcodeCalendar
	) {
		return {
			restrict: 'A',
			transclude: true,
			link: function (scope, element, attrs) {
				var config = seedcodeCalendar.get('config');
				var draggableElement = element.find('.add-event-button');
				var draggableContainer = element.find('.add-event-container');
				var distance = 15;
				var revertDelay = 300;
				var cancelDrop;
				var altViewHover;

				// it doesn't need to have a start or end
				var eventObject = {
					title: '', // use the element's text as the event title
				};

				// store the Event Object in the DOM element so we can get to it later
				draggableElement.data('eventObject', eventObject);

				//Initialize classes so we don't allow drop on ourself
				draggableElement.addClass('cancel-drop');

				// make the event draggable using jQuery UI
				draggableElement.draggable({
					zIndex: 99,
					revert: false,

					start: function (event, ui) {
						$rootScope.$broadcast('closePopovers');
						altViewHover = $.fullCalendar.reportAltViewDragStart(
							event,
							ui
						);
						draggableContainer.addClass('dragging');
					},

					drag: function (event, ui) {
						altViewHover = $.fullCalendar.reportAltViewDrag(
							event,
							ui
						);
						//Cancel the drop, we are over our cancel pad
						if (isHome(ui, distance)) {
							if (!cancelDrop) {
								draggableElement.addClass('cancel-drop');
								draggableContainer.removeClass('dragging');
							}
						}
						//We are outside of our cancel pad so allow dropping
						else if (cancelDrop === undefined || cancelDrop) {
							draggableElement.removeClass('cancel-drop');
							draggableContainer.addClass('dragging');
						}
					},

					stop: function (event, ui) {
						var dropSuccessful =
							draggableElement.hasClass('dropped');
						draggableContainer.removeClass('dragging');
						//Reset our dropped status
						draggableElement.removeClass('dropped');

						altViewHover = $.fullCalendar.reportAltViewDragStop(
							event,
							ui
						);

						//Check if we dropped on our cancel pad
						if (altViewHover && ui?.helper?.data) {
							ui.helper.data.isUnscheduled = true;
							calendarEvents.onExternalEventDrop(
								$.fullCalendar.moment().stripTime(),
								null,
								event,
								ui,
								null
							);
						}

						if (dropSuccessful || altViewHover) {
							draggableContainer.addClass('dropped');
							draggableElement.css('opacity', '');
							window.setTimeout(function () {
								draggableElement.css('left', '').css('top', '');
								draggableContainer.removeClass('dropped');
							}, revertDelay);
						} else {
							//Did not drop in a valid spot so let's revertDuration
							draggableContainer.addClass('cancel-drop');
							draggableElement.css('left', '').css('top', '');
							window.setTimeout(function () {
								draggableContainer.removeClass('cancel-drop');
							}, revertDelay);
						}
					},
				});
				draggableElement.on('click', newEventClick);

				//Add touch capability to the element
				draggableElement.addTouch(false, 0);

				//Destroy the draggable event when removing the element from the DOM
				element.on('$destroy', function () {
					draggableElement.draggable('destroy');
				});

				function isHome(ui, distance) {
					if (
						ui.position.top <= ui.originalPosition.top + distance &&
						ui.position.top >= ui.originalPosition.top - distance &&
						ui.position.left <=
							ui.originalPosition.left + distance &&
						ui.position.left >= ui.originalPosition.left - distance
					) {
						return true;
					}
				}

				function newEventClick() {
					var allDay = true;
					var eventTitle = config.newEventTitleText;
					var showWeekends = config.weekends;
					var view = seedcodeCalendar
						.get('element')
						.fullCalendar('getView');
					var date = moment(config.defaultDate);
					var now = moment();

					//Add a click throttle to prevent multiple events from a double click
					if (config.preventClick) {
						return;
					}
					config.preventClick = true;

					//Change date to now if now is in view
					if (
						view.end.diff(view.start, 'days') > 1 &&
						!now.isBefore(view.start) &&
						!now.isAfter(view.end)
					) {
						date = now;
					}

					//Set to the start of the current hour
					date.set('hour', now.get('hour')).set('minute', 0);

					//Check if target date is visible. If not move new event to next visible day
					if (
						!showWeekends &&
						(date.day() === 6 || date.day() === 0)
					) {
						while (date.day() > 5 || date.day() < 1) {
							date.add(1, 'day');
						}
					}

					//Verify time is in view
					//If not, set to the first visible time
					if (
						date.hour() < config.minTime.split(':')[0] ||
						date.hour() > config.maxTime.split(':')[0]
					) {
						date.set('hour', config.minTime.split(':')[0]).set(
							'minute',
							0
						);
					}

					//Restore ability to click after period of time
					window.setTimeout(function () {
						config.preventClick = false;
					}, 500);

					//If we just dropped from a drag ignore this click as the button may not have returned home
					if (draggableContainer.hasClass('dropped')) {
						return;
					}
					scope.$evalAsync(function () {
						calendarIO.newEvent(date, allDay, eventTitle, null);
					});
				}
			},
			template:
				'<div class="add-event-container"><div class="add-event-cancel"></div><div class="add-event-button"></div></div>',
		};
	}
})();
